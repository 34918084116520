.hero {
  background-image: url("../../../../public/images/wallpaper3.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  /* z-index: -1; */
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
.hero .container{
  /* background: red; */
  display: flex;
  justify-content: center;
}
.hero .row {
  width: 60%;
  /* background: rebeccapurple; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.hero #heading{
  text-align: center;
}

.hero #heading h1{
  font-size: 4rem;
}

@media screen and (max-width: 1288px) {
.hero .row{
  width: 85%;
}
}
@media screen and (max-width: 988px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: right;
    padding-top: 30%;
    /* height: 80vh; */
    padding-right: unset;
  }
  .hero .row {
    width: 100%;
  }
  .hero #heading{
    text-align: center;
    padding: 3rem 0 0 0;
  }
  .hero #heading h1{
    font-size: 30px;
  }
  .hero .row p{
    text-align: center;
  }
  .hero .button{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 628px) {

.hero{
  padding-top: 50%;
}
.hero p{
  font-size: 12px;
}
}