/*--------head------------*/
.head {
  padding: 20px 0;
  color: #fff;
  position: relative;
  z-index: 99;
}
.logo h1 {
  font-size: 35px;
  font-weight: 900;
}
.head .icon {
  margin-left: 10px;
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
  position: relative;
  z-index: 99;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #1eb2a6;
}
.start {
  background-color: #1eb2a6;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}

.dropdown-menu{
  position: absolute;
  background: white;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  cursor: pointer;
}
.dropdown-menu li{
  /* background: red; */
  width: 100%;
}
.linkdrop{
  color: #1eb2a6;
}
.linkdrop:hover{
  color: black;
}
.dropdown-link{
cursor: pointer;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #1eb2a6;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #1eb2a6;
  }
 
}
